<template>
  <div class='xtx-goods-page'>
    <div class="container" v-if="!loading && goods">
      <!-- 面包屑 -->
      <!-- <XtxBread>
        <XtxBreadItem to="/">首页</XtxBreadItem>
        <XtxBreadItem :to="`/category/${goods.categories[1].id}`">{{goods.categories[1].name}}</XtxBreadItem>
        <XtxBreadItem :to="`/category/sub/${goods.categories[0].id}`">{{goods.categories[0].name}}</XtxBreadItem>
        <XtxBreadItem>{{goods.name}}</XtxBreadItem>
      </XtxBread> -->
      <!-- 商品信息 -->
      <div class="goods-info">
        <div class="media">
          <GoodsImage :images="sliderImage" />
          <!-- <GoodsSales /> -->
        </div>
        <div class="spec">
          <GoodsName :goods="goods" />
          <!-- sku组件 skuId="1369155865461919746" 测试选中 -->
          <!-- <GoodsSku :goods="goods" @change="changeSku" /> -->
          <!-- 数量选择组件 -->
          <XtxNumbox label="数量" v-model="num" :max="goods.inventory" />
          <!-- 按钮组件 -->
          <XtxButton @click="insertCart()" type="primary" style="margin-top:20px">加入购物车</XtxButton>
        </div>
      </div>
      <!-- 商品推荐 -->
      <!-- <GoodsRelevant :goodsId="goods.id" /> -->
      <!-- 商品详情 -->
      <div class="goods-footer">
        <div class="goods-article">
          <!-- 商品+评价 -->
          <GoodsTabs />
          <!-- 注意事项 -->
          <!-- <GoodsWarn /> -->
        </div>
        <!-- 24热榜+周热销榜 -->
        <div class="goods-aside">
          <!-- <GoodsHot /> -->
          <!-- <GoodsHot :type="2" /> -->
        </div>
      </div>
    </div>


    <div v-else class="loading"></div>
  </div>
</template>

<script>
import GoodsRelevant from './components/goods-relevant'
import GoodsImage from './components/goods-image'
import GoodsSales from './components/goods-sales'
import GoodsName from './components/goods-name'
import GoodsSku from './components/goods-sku'
import GoodsTabs from './components/goods-tabs'
import GoodsHot from './components/goods-hot'
import GoodsWarn from './components/goods-warn'
import { nextTick, provide, reactive, ref, watch } from 'vue'
import { findGoods } from '@/api/product'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import Message from '@/components/library/Message'
import { addCart, getDetail } from '@/api/home'
import { gotopage } from '@/hooks'
export default {
  name: 'XtxGoodsPage',
  components: { GoodsRelevant, GoodsImage, GoodsSales, GoodsName, GoodsSku, GoodsTabs, GoodsHot, GoodsWarn },
  setup () {
    const { goods, loading,sliderImage,slider ,attrvalue} = useGoods()
    // const changeSku = (sku) => {
    //   // 修改商品的现价原价库存信息
    //   if (sku.skuId) {
    //     goods.value.price = sku.price
    //     goods.value.oldPrice = sku.oldPrice
    //     goods.value.inventory = sku.inventory
    //   }
    //   // 记录选择后的sku，可能有数据，可能没有数据{}
    //   currSku.value = sku
    // }


    let route = useRoute()
    // let id = route.params.id
    // getDetail({productId:id}).then((res)=>{
    //   console.log(res);
    // })


    // 提供goods数据给后代组件使用
    provide('goods', goods)
    provide('slider',sliderImage)

    // 选择的数量
    const num = ref(1)

    // 加入购物车
    const store = useStore()
    const currSku = ref(null)

    const postData = reactive({
      cartNum:num,
      isNew:false,
      productAttrUnique:attrvalue,
      productId:route.params.id
    })
    const insertCart = () => {
      // if (currSku.value && currSku.value.skuId) {
      //   // id skuId name attrsText picture price nowPrice selected stock count isEffective
      //   const { skuId, specsText: attrsText, inventory: stock } = currSku.value
      //   const { id, name, price, mainPictures } = goods.value
      //   store.dispatch('cart/insertCart', {
      //     skuId,
      //     attrsText,
      //     stock,
      //     id,
      //     name,
      //     price,
      //     nowPrice: price,
      //     picture: mainPictures[0],
      //     selected: true,
      //     isEffective: true,
      //     count: num.value
      //   }).then(() => {
      //     Message({ type: 'success', text: '加入购物车成功' })
      //   })
      // } else {
      //   Message({ text: '请选择完整规格' })
      // }
          addCart(postData).then((res)=>{
            console.log(res);
            if(res.code == 200){
              Message({ type: 'success', text: '加入购物车成功' })
            }else if(res.code == 402){
              Message({ type: 'warn', text: '请先登录' })
              gotopage('/login')
            }
          })

    }

    return { goods, loading, num, insertCart,sliderImage,slider }
  }
}
// 获取商品详情
const useGoods = () => {
  // 出现路由地址商品ID发生变化，但是不会重新初始化组件
  const goods = ref(null)
  const route = useRoute()
  const loading = ref(false)
  const sliderImage = ref([])
  const attrvalue = ref(null)
  watch(() => route.params.id, (newVal) => {
    if (newVal && `/product/${newVal}` === route.path) {
      loading.value = true
      getDetail({productId:route.params.id}).then(data => {
        // 让商品数据为null然后使用v-if的组件可以重新销毁和创建
        goods.value = null
        nextTick(() => {
          // console.log(JSON.parse(data.data.sliderImage));
          console.log(data.data);
          goods.value = data.data
          attrvalue.value = data.data.attrValue[0].id
          console.log(data.data.attrValue[0].id);
          sliderImage.value =JSON.parse(data.data.sliderImage) 
          loading.value = false
        })
      })
    }
  }, { immediate: true })
  return { goods, loading ,sliderImage,attrvalue}
}
</script>

<style scoped lang='less'>
.loading {
  height: 580px;
  width: 1240px;
  margin: 72px auto 20px;
  background: rgba(255,255,255,.9) url(../../assets/images/loading.gif) no-repeat center;
}
.goods-info {
  min-height: 600px;
  background: #fff;
  display: flex;
  .media {
    width: 580px;
    height: 600px;
    padding: 30px 50px;
  }
  .spec {
    flex: 1;
    padding: 30px 30px 30px 0;
  }
}
.goods-footer {
  // display: flex;
  margin-top: 20px;
  .goods-article {
    width: 1246px;
    margin-right: 20px;
  }
  // .goods-aside {
  //   width: 280px;
  //   min-height: 1000px;
  // }
}
// .goods-tabs {
//   min-height: 600px;
//   background: #fff;
// }
// .goods-warn {
//   min-height: 600px;
//   background: #fff;
//   margin-top: 20px;
// }
</style>
